import React from 'react';
import { Layout } from 'antd';
import './index.css';

const { Footer: AntFooter } = Layout;

const Footer = () => {

  const currentPath = window.location.pathname;
  const selectedClassName = 'nav-li-selected';
  const getSelected = (path: string): boolean => {
    return currentPath === path;
  };

  const getSelectedClassName = (title: string, path: string) => {
    const isSelected = getSelected(path);
    return isSelected ? selectedClassName : '';
  };

  const renderNavList = () => {
    const navData = [{
      title: 'Home',
      path: '/',
    }, {
      title: 'Career',
      path: '/career',
    }, {
      title: 'Privacy policy',
      path: '/privacy-policy',
    }, {
      title: 'Terms and conditions',
      path: '/terms-and-conditions',
    }];
    return (
      <ul className='nav-ul'>
        {navData.map((data) => (
          <li
            title={data.title}
            key={data.title}
            className={`nav-li ${getSelectedClassName(data.title, data.path)}`}
            onClick={() => {
              window.location.href = data.path;
            }}
          >
            <a
              className='nav-title'
              href={data.path}
            >
              {data.title}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <AntFooter className='app-footer'>
      <div className='footer-nav'>
        <div className='copyright'>
          <span style={{
            marginBottom: 5
          }}>
            JIEYING LIMITED
          </span>
          <span>
            © witchuapp.com 2022
          </span>
        </div>
        {renderNavList()}
      </div>
    </AntFooter>
  );
};

export default Footer;